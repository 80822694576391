<template>
  <v-container>
    <perseu-card title="Chakras">
      <v-btn color="secondary" slot="title-right" @click="create">
        Novo Chakra
      </v-btn>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col cols="12" sm="2">
              <v-switch v-model="query.active" label="Ativos"></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="query.location"
                label="Localização"
                @keyup.enter.native="list"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="query.name"
                label="Nome"
                @keyup.enter.native="list"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar </v-btn>
          </v-row>
        </filters-panel>
        <v-data-table :items="chakras" :headers="headers" class="elevation-1">
          <template v-slot:item.order="{ item }">
            <number-arrows
              :number="item.order"
              @change="(direction) => changeOrder({ item, direction })"
            />
          </template>
          <template v-slot:item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
            <v-btn
              small
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon small>check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <form-chakra ref="form" @close="closedForm" />
  </v-container>
</template>

<script>
import {
  getAll,
  update,
  create,
  getOne,
  changeOrder,
} from "@/services/chakras-service";

export default {
  components: {
    "form-chakra": () => import("@/components/Chakras/Form"),
  },
  data: () => ({
    headers: [
      { text: "Núm.", value: "order" },
      { text: "Nome", value: "name" },
      { text: "Localização", value: "location" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    chakras: [],
    chakra: {},
    query: {
      active: true,
    },
  }),
  created() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await getAll(this.query);
        this.chakras = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.form.create();
    },
    async edit({ id }) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getOne(id);
        this.$refs.form.edit(data);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await update(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeFromList(chakra) {
      const index = this.findIndexItem(chakra);
      this.chakras.splice(index, 1);
    },
    findIndexItem(chakraParam) {
      return this.chakras.findIndex((chakra) => chakra.id === chakraParam.id);
    },
    async closedForm(chakra) {
      try {
        this.$store.dispatch("loading/openDialog");
        if (chakra.id) {
          await update(chakra);
          const index = this.findIndexItem(chakra);
          this.chakras.splice(index, 1, chakra);
        } else {
          const { data } = await create(chakra);
          this.chakras.push(data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async changeOrder({ item, direction }) {
      const index = this.findIndexItem(item);
      try {
        await changeOrder({ chakra: item, direction });
        await this.list();
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>

<style></style>
