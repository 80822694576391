import api from "./api-service";
import { stringify } from "querystring";

const url = "chakras";

export const getAll = (filter) => api().get(`${url}?${stringify(filter)}`);

export const getOne = (id) => api().get(`${url}/${id}`);

export const create = (chakra) => api().post(url, chakra);

export const update = (chakra) => api().put(url, chakra);

export const changeOrder = ({ chakra, direction }) => {
  return api().put(`${url}/order/direction/${direction}`, chakra);
};
